
import { defineComponent } from "vue";
import Loading from "@/components/Basic/Loading.vue";

export default defineComponent({
  name: "RedirectPage",
  components: {
    Loading,
  },
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.redirectLinkToPage();
  },
  methods: {
    async redirectLinkToPage() {
      // console.log("redirectLinkToPage()");
      const code = this.$router.currentRoute.value.params.code;
      const value = this.$router.currentRoute.value.params.value;
      let key = "";
      if (typeof this.$router.currentRoute.value.query == "object") {
        const keys = Object.keys(this.$router.currentRoute.value.query);
        if (keys.length) {
          key = keys[0];
        }
      }

      if (code == "l") {
        key = String(value);
      }

      switch (code) {
        case "l":
        case "join":
          await this.$store.dispatch("referral/validateReferralLink", key, {
            root: true,
          });
          if (this.$store.getters["referral/error"]) {
            this.$router.push({ name: "pageNotFound404" });
            break;
          }
          this.$router.replace("/signup?" + key);
          break;

        case "r":
        default:
          if (value) {
            const page = (value + "").charAt(0).toUpperCase();
            switch (page) {
              case "C":
                this.$router.push({
                  name: "ConfirmCanditate",
                  query: { id: value },
                });
                break;
              default:
                this.$router.push({ name: "pageNotFound404" });
            }
          } else {
            this.$router.push({ name: "pageNotFound404" });
          }
      }
    },
  },
});
